<template>
	<div class="dropDown" :class="{active: active}">
		<div class="dropDown__header" @click="openDropDown">
			<div class="dropDown__header__left" v-if="buttonText">
				<p class="dropDown--title" v-html="title">{{title}}</p>
				<p class="dropDown--desc" v-html="desc"></p>
			</div>
			<div class="dropDown__header__content" v-else>
				<p class="dropDown--title" v-html="title">{{title}}</p>
				<p class="dropDown--desc" v-html="desc"></p>
			</div>
			<div class="dropDown__header__right" v-if="buttonText">
				<a @click="$emit('buttonClick', input, value, form)">{{buttonText}}</a>
			</div>
		</div>
		<div class="dropDown__body">
			<div class="dropDown__content" v-html="content"></div>
			<div class="dropDown__buttons" v-if="showHideButton || buttonText">
				<div v-if="buttonText">
					<a class="button button--red" @click="$emit('buttonClick', input, value, form)">{{buttonText}}</a>
				</div>
				<div v-if="showHideButton">
					<a @click="openDropDown">Свернуть</a>
				</div>
			</div>
		</div>
	</div>
</template>



<style lang="sass">
	@import '@/assets/sass/DropDown'
</style>

<script>
	export default {
		props: ['title', 'desc', 'content', 'buttonText', 'value', 'form', 'input', 'showHideButton'],
		data: () => ({
			active: false
		}),
		methods: {
			openDropDown: function(e){
				var dropDown = e.target.closest('.dropDown');
				if(!e.target.parentElement.classList.contains('dropDown__header__right')){
					if(dropDown.classList.contains('active')){
						var height = dropDown.getElementsByClassName("dropDown__body")[0].offsetHeight;
						dropDown.getElementsByClassName("dropDown__body")[0].style.height = height + "px";
						setTimeout(function(){
							dropDown.getElementsByClassName("dropDown__body")[0].style.height = "0";
						});						
					} else{
						var width = dropDown.offsetWidth;
						var clone = document.createElement("div");
						clone.style.height = "auto";
						clone.style.position = "absolute";
						clone.style.top = "0";
						clone.style.left = "0";
						clone.style.visibility = "hidden";
						clone.style.width = width + "px";
						dropDown.getElementsByClassName("dropDown__body")[0].classList.forEach(function(className, index) {
							clone.classList.add(className);
						});
						clone.classList.add('fakeElement');
						clone.innerHTML = dropDown.getElementsByClassName("dropDown__body")[0].innerHTML;

						document.body.append(clone);
						var height = document.body.getElementsByClassName('fakeElement')[0].offsetHeight;
						document.body.getElementsByClassName('fakeElement')[0].remove();

						dropDown.getElementsByClassName("dropDown__body")[0].style.height = height + "px";

						setTimeout(function(){
							if(dropDown.getElementsByClassName("dropDown__body")[0].style.height == height + "px"){
								dropDown.getElementsByClassName("dropDown__body")[0].style.height = "auto";
							}
						}, 400);
					}

					this.active = !this.active;
				}
			}
		}
	}
</script>